.header {
  min-height: $header-h !important;
  position: relative;
  width: 100%;
  z-index: 1000;

  @include v-md {
    min-height: $header-md-h !important;
  }
  @include v-sm {
    min-height: $header-sm-h !important;
  }

  &__navigation {
    gap: 80px !important;
    @include v-md-lg {
      gap: 50px !important;
    }
    @include v-md {
      gap: 46px !important;
    }
    @include v-sm {
      display: none !important;
    }
  }
}

.header__right-block {
  display: flex;
  gap: 20px;
  align-items: center;
  @include v-md {
    gap: 12px;
  }
  @include v-sm {
    gap: 15px;
    margin-right: 15px;
  }
  @include v-xxs {
    gap: 4px;
    margin-right: 0;
  }
}

.lang-menu {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
  border: 1px solid #fafafa;
  border-radius: 8px;
  width: 136px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border: 1px solid $goldHovered;
    .lang-menu__current {
      .lang-menu__name {
        color: $goldHovered;
      }
      .lang-menu__arrow {
        stroke: $goldHovered;
      }
    }
  }

  &__currant-item {
    position: relative;
    left: -4px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    background-color: transparent;
    color: $white;
    border-radius: 8px;
  }

  &__target {
    position: relative;
    left: -4px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    background-color: transparent;
    color: $white;
    border-radius: 8px;
    @include v-sm {
      left: -4px;
      gap: 4px;
    }
    @include v-xs {
      left: -4px;
      gap: 6px;
    }
  }

  &__name {
    font-size: 20px;
  }

  &__list {
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 52px;
    right: -1px;
    background-color: $green;
    padding: 8px 16px;
    border: 1px solid #fafafa;
    border-radius: 8px;
    gap: 8px;
    transition: opacity 0.3s ease;
    li {
      position: relative;
      left: 4px;

      &:hover {
        .lang-menu__name {
          color: $goldHovered;
        }
      }
    }
    @include v-md {
      padding: 4px 8px;
      border-radius: 5px;
    }
    @include v-sm {
      padding: 2px 4px;
      gap: 5px;
      border-radius: 3px;
    }
  }

  &__arrow {
    transition: 0.3s transform;
    &_open {
      transform: rotate(-180deg);
    }
  }

  &__list_open {
    opacity: 1;
    visibility: visible;
    position: absolute;
    z-index: 1001;
    @include v-md {
      top: 32px;
    }
    @include v-sm {
      top: 24px;
    }

    .lang-menu__target {
      @include v-sm {
        position: relative;
      }
    }

    .lang-menu__name {
      @include v-sm {
        display: block;
      }
      @include v-xs {
        order: 1;
      }
    }

    .lang-menu__flag {
      @include v-sm {
        display: block;
      }
      @include v-xs {
        order: 2;
      }
    }
  }

  @include v-md {
    height: 30px;
    width: 80px;
    padding: 0 5px 0 14px;
    border-radius: 5px;
    &__flag {
      height: 14px;
    }
    &__name {
      font-size: 12px;
    }
    &__arrow {
      width: 14px;
    }
    &__currant-item {
      gap: 5px;
    }
  }
  @include v-sm {
    height: 22px;
    width: 42px;
    padding: 0 3px 0 12px;
    border-radius: 3px;
    &__flag {
      position: relative;
      height: 10px;
    }
    &__name {
      position: relative;
      left: 2px;
      font-size: 8px;
    }
    &__arrow {
      display: none;
    }
    &__currant-item {
      gap: 3px;
    }
  }
  @include v-xs {
    width: 28px;
    &__name {
      display: none;
    }
  }
}

.burger {
  position: relative;
  display: none;
  width: 19px;
  height: 19px;
  cursor: pointer;
  margin-top: 2px;
  margin-left: 4px;
  @include v-sm {
    display: block;
  }
  span {
    display: block;
    width: 100%;
    background-color: #fafafa;
    border-radius: 1px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    &.tt {
      position: absolute;
      left: 0;
      top: 0;
    }
    &.mm {
      position: absolute;
      left: 0;
      top: 7px;
    }
    &.bb {
      position: absolute;
      left: 0;
      top: 14px;
    }
  }
  &:hover {
    span {
      background-color: $goldHovered;
    }
  }
  &.open {
    .tt {
      transform: rotate(45deg);
      top: 7px;
    }
    .mm {
      opacity: 0;
    }
    .bb {
      top: 7px;
      transform: rotate(-45deg);
    }
  }
}
