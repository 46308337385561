// colors:
$bgHeader: linear-gradient(179.5deg, #152d2c 20.71%, #010303 55.83%, #152d2c 87.32%);
$bgFooter: linear-gradient(#152d2c 15%, #010303 39%, #152d2c 73%);
$bgDim: #c8c2be;

$white: #fafafa;
$beige: #fdf6ea;
$goldHovered: #e8a32e;
$gold: #c98d26;
$darkgreen: #152d2c;
$dark: #010303;
$black: #101010;
$green: #142b2a;

// fonts:
$fontBasic: 'Raleway', sans-serif;
$fontTitle: 'Roboto', sans-serif;

// sizes:
$header-h: 120px;
$header-md-h: 70px;
$header-sm-h: 60px;
$hero-min-h: 912px;
$hero-md-min-h: 510px;
$hero-xs-min-h: 560px;
