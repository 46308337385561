// Extra Large screens
@mixin v-xl {
  @media only screen and (min-width: 1441px) {
    @content;
  }
}

// Large screens
@mixin v-lg {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin v-md-lg {
  @media only screen and (min-width: 1025px) and (max-width: 1210px) {
    @content;
  }
}

// Medium screens
@mixin v-md {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

// Small screens
@mixin v-sm {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

// Extra Small screens
@mixin v-xs {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

// Extra Extra Small screens
@mixin v-xxs {
  @media only screen and (max-width: 350px) {
    @content;
  }
}
