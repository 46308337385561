.footer {
  margin-top: auto;
  padding: 6.25rem 0;
  border-top: 0.125rem solid $white;
  // background: $bgFooter;
  background: #152d2c;
  color: $white;
  font-family: Raleway;
  font-feature-settings: 'lnum';
  @include v-md {
    padding: 3.375rem 0;
  }

  &__content {
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;
    @include v-md {
    }
    @include v-sm {
      flex-direction: column;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: $goldHovered;
    }
  }

  &__container {
    justify-content: space-between;
  }

  .logo {
    @include v-xs {
      height: 2.25rem;
    }
  }

  &__navigation {
    display: flex;
    max-width: 53.5rem;
    width: 62%;
    column-gap: 2rem;
    margin-left: auto;
    @include v-md {
      margin: 0 auto;
    }
    @include v-sm {
      width: 100%;
      column-gap: 1rem;
      margin: 1.5rem auto 0;
    }
    @include v-xs {
      order: 2;
      flex-direction: column;
    }
  }

  &__navigation-col {
    row-gap: 1rem;
  }


  &__navigation-item {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    @include v-md-lg {
      font-size: 1rem;
    }
    @include v-md {
      font-size: 0.75rem;
    }
    @include v-xs {
      display: flex;
    }
  }

  &__contacts-heading {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: inherit;
    margin-bottom: 1.5rem;
    @include v-md-lg {
      font-size: 1rem;
    }
    @include v-md {
      font-size: 0.75rem;
    }
    @include v-md {
      margin-bottom: 0.875rem;
      font-size: 0.75rem;
    }
  }

  &__contact-wrapper {
    white-space: wrap;
    width: 12.375rem;
    @include v-md {
      width: 7.188rem;
    }
    @include v-xs {
      width: 9.938rem;
    }
  }

  &__contact-info {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: baseline;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    @include v-md {
      margin-bottom: 0.313rem;
    }
    @include v-xs {
      margin-bottom: 0.25rem;
      gap: 1.375rem;
    }
  }

  &__contact-name {
    font-size: 1rem;
    @include v-md {
      font-size: 0.563rem;
    }
    @include v-xs {
      font-size: 0.75rem;
    }
  }

  &__contact-description {
    position: relative;
    font-size: 0.875rem;
    line-height: 1.313rem;
    @include v-md {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    @include v-xs {
      font-size: 0.625rem;
    }
  }

  &__subscription {
    width: 100%;
    @include v-xs {
      position: relative;
      margin: 0 auto;
    }
  }

  &__subscription-text {
    margin-top: 3.125rem;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: 0.015rem;
    @include v-md {
      margin-top: 1.875rem;
      letter-spacing: 0.006rem;
      font-size: 0.724rem;
    }
    @include v-xs {
      margin-top: 1.5rem;
      text-align: left;
      letter-spacing: 0.008rem;
      line-height: normal;
    }
  }

  &__input-container {
    display: flex;
    position: relative;
    margin: 2.25rem auto 0;
    align-items: center;
    height: 3rem;
    @include v-md {
      height: 1.75rem;
      margin-top: 1.25;
    }
    @include v-xs {
      height: 1.875rem;
      margin-top: 1.063rem;
    }
  }

  &__input {
    padding: 0.625rem;
    height: 100%;
    width: 21.875rem;
    border: none;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    font-family: Raleway;
    font-size: 1.125rem;
    font-weight: 600;
    color: #5f5f5f;
    @include v-md {
      padding: 0.362rem;
      width: 12.75rem;
      font-size: 0.651rem;
      border-radius: 0.29rem 0rem 0rem 0.29rem;
    }
    @include v-xs {
      font-size: 0.75rem;
      width: 80%;
    }
  }

  &__submit-btn {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 1rem 1.5rem;
    border: none;
    size: 1rem;
    font-weight: 600;
    background-color: $gold;
    color: $white;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    &:hover {
      background-color: $goldHovered;
    }
    @include v-md {
      border-radius: 0rem 0.2896rem 0.2896rem 0rem;
      font-size: 0.5625rem;
      padding: 0.5791rem 0.8687rem;
    }
    @include v-sm {
      font-size: 0.75rem;
      padding: 0.5791rem 0.8687rem;
    }
  }

  &__no-copyright {
    position: relative;
    // margin-top: 3.625rem;
    font-size: 1.25rem;
    font-weight: 400;
    opacity: 0.45;
    text-align: center;
    letter-spacing: 0rem;
    @include v-md {
      font-size: 0.724rem;
      margin-top: 2.125rem;
    }
    @include v-xs {
      margin-top: 0.5rem;
    }
  }
}

.my-form {
  width: 100%;
  height: 500px;
}
