.modal-title {
  font-size: 1.125rem;
  color: #010303;
}

.modal-address iframe {
  width: 100%;
  height: 28.125rem;
  border: 0;
}
