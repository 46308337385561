.button {
  display: inline-block;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  text-decoration: none;
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.12;
  cursor: pointer;
  width: 14.75rem;
  height: 3.125rem;
  color: $white;

  @include v-xs {
    width: 70%;
    font-size: 0.875rem;
  }

  &:hover {
    background-color: $goldHovered;
  }
}

.bbl {
  border-bottom-left-radius: 0px !important;
}

.btn-header {
  display: flex;
  align-items: center;
  width: 9.375rem;

  @include v-md {
    font-size: 0.625rem;
    width: 5.375rem;
    height: 1.875rem;
  }
  @include v-sm {
    padding: 0.375rem;
    height: 1.5rem;
    width: 4.438rem;
  }
}

.btn-hero {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 575px) {
    width: 70%;
  }
}

button#sp-4ee033e4-dd65-4275-a6b5-9b4eabcc48ff {
  display: inline-block;
  width: 14.75rem;
  height: 3.125rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  background-color: $gold;
  cursor: pointer;
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.12;
  color: $white;
  text-align: center;
  text-decoration: none;
  margin-top: 60px;

  @include v-xs {
    width: 70%;
    font-size: 0.875rem;
  }

  &:hover,
  &:focus {
    background-color: $goldHovered;
  }
}


.btn-cookie {
  display: inline-block;
  text-align: center;
  // padding: 0.625rem 0rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0px 1px 1px 0px #0000001F, 0px 0px 0px 1px #676E7629, 0px 2px 5px 0px #676E7614;
  outline: none;
  text-decoration: none;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.4;
  cursor: pointer;
  width: 7.6875rem;
  height: 2.5rem;
  color: #142B2A;
  background-color: $white;

  @include v-xs {
    width: 30%;
    font-size: 0.7rem;
  }
}

.w-196 {
  width: 12.25rem !important;

  @include v-xs {
    width: 45% !important;
  }

}

.bsn {
  box-shadow: none;
}

.bn {
  border: none !important;
  outline: none !important;
}

.checkBtn {
    position: absolute;
    top: 40%;
    left: 0;
    width: 36px;
    height: 20px;
    border-radius: 100px;
    border: none;
    outline: none;
    padding: 2px;
    background-color:#E5E7EA;
    transition: all 0.4s ease-in-out;


    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      border-radius: 10px;
      background-color: #FAFAFA;
      box-shadow: rgba(103, 110, 118, 0.08) rgba(0, 0, 0, 0.12);
      transition: all 0.4s ease-in-out;
    }
}

.input {
  display: none;
}

.input:checked + .checkBtn {
  background-color: #142B2A;
}

.input:checked + .checkBtn::before {
  transform: translateX(16px);
}