.burger-menu {
  display: none;
  z-index: 999;
  @include v-sm {
    padding-top: $header-sm-h;
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: $white;
    color: $black;
    transform: translateX(100%);
    transition: 0.3s transform ease-in-out;
  }

  li {
    border-bottom: 1px solid $green;
    padding: 1.25rem 0.625rem;
  }

  a {
    color: $black;
    text-decoration: none;
  }

  &.active {
    transform: translateX(0);
  }
}
