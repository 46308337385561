.logo {
  position: relative;
  height: 3.875rem;
  width: max-content;
  cursor: pointer;

  img {
    position: relative;
    height: 100%;
  }
  @include v-md {
    height: 2.25rem;
  }
  @include v-sm {
    height: 1.438rem;
    top: -0.063rem;
  }
}
