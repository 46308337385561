.text-link {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  text-decoration: none;

  @include v-md {
    font-size: 0.875rem;
  }

  &:hover,
  &:focus {
    color: $goldHovered !important;
  }
}

.main-title {
  margin: 0;
  font-family: Roboto;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.17;
  @include v-md {
    font-size: 2.313rem;
  }
  @include v-sm {
    font-size: 2rem;
  }
}

.main-title-2 {
  font-size: 2rem;
  line-height: 1.5;

  @include v-md {
    font-size: 1.125rem;
  }
  @include v-sm {
    font-size: 1.25rem;
  }
}

.main-subtitle {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.5;
  margin-bottom: 5rem;

  @include v-md {
    font-size: 1.5rem;
    margin-bottom: 3.75rem;
  }

  @include v-sm {
    font-size: 1.25rem;
    margin-bottom: 5rem;
  }
}

.title {
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 1.4;
  margin-bottom: 2.5rem;
  padding-top: 40px;

  @include v-sm {
    font-size: 1.438rem;
    margin-bottom: 1.438rem;
    padding-top: 20px;
  }
}

.t-text {
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5;

  @include v-md {
    font-size: 1.25rem;
  }
}

.title-s {
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.17;

  @include v-md {
    font-size: 1rem;
  }
}

.title-2 {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.5;

  @include v-md {
    font-size: 1.125rem;
  }
}

.subtitle {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5;

  @include v-md {
    font-size: 0.875rem;
  }
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fs-18 {
  font-size: 1.125rem !important;

  @include v-md {
    font-size: 1rem !important;
  }

  @include v-xs {
    font-size: 0.875rem !important;
  }
}

.fs-14 {
  font-size: 0.875rem !important;

  @include v-md {
    font-size: 0.75rem !important;
  }
}

.fs-24 {
  font-size: 1.5rem !important;

  @include v-md {
    font-size: 0.875rem !important;
  }
}

.fs-30 {
  font-size: 1.875rem !important;

  @include v-md {
    font-size: 1.25rem !important;
  }
}

.text-2 {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;

  @include v-md {
    font-size: 0.875rem;
  }

  @include v-sm {
    font-size: 0.75rem;
  }
}

.cookie-title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5;
  color: $goldHovered;

  @include v-md {
    font-size: 1rem;
  }
}

.cookie-text {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: $black;
  text-align: justify;

  @include v-md {
    font-size: 0.875rem;
  }
}