.go-up {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1.5rem;
  right: 0;
  bottom: 0;
  gap: 0.25rem;
  font-size: 1rem;
  width: 5.125rem;
  height: 5.125rem;
  background-color: #152d2c;
  color: $white;
  border-radius: 50%;
  border: 1px solid $white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 999;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @include v-md {
    width: 2.938rem;
    height: 2.938rem;
    margin: 0.875rem;
    gap: 0.125rem;
    font-size: 0.75rem;
    img {
      height: 0.75rem;
    }
  }
}

.go-up_visible {
  opacity: 0.6;
  visibility: visible;
}
