.c-white {
  color: $white !important;
}
.c-goldhovered {
  color: $goldHovered !important;
}
.c-gold {
  color: $gold !important;
}
.c-green {
  color: $green !important;
}
.c-darkgreen {
  color: $darkgreen !important;
}
.c-black {
  color: $black !important;
}

.bg-c {
  &-dark {
    background-color: $green;
  }
  &-light {
    background-color: $white;
  }
  &-beige {
    background-color: $beige;
  }
  &-gold {
    background-color: $gold;
  }
  &-darkgreen {
    background-color: $darkgreen;
  }
  &-goldHovered {
    background-color: $goldHovered !important;
  }
}
.bg {
  &-header {
    background: $bgHeader;
  }
  &-header {
    background: $bgFooter;
  }
}
