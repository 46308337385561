.container {
  margin: 0 auto;
  padding: 0;
  width: calc(100% - 5.25rem);
  @include v-sm {
    width: calc(100% - 3rem);
  }
}

.p-container {
  padding-top: 160px !important;
  padding-bottom: 160px !important;

  @include v-sm {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  @include v-xs {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

.disable-scroll {
  overflow: hidden;
}

.section {
  margin-top: -240px;
  min-height: 310px;
  @include v-md {
    margin-top: -140px;
    min-height: 74px;
    min-height: 194px;
  }
  @include v-sm {
    margin-top: -90px;
    min-height: 174px;
  }
}

.round-section {
  &-right {
    border-top-right-radius: 240px;
    @include v-md {
      border-top-right-radius: 140px;
    }
    @include v-sm {
      border-top-right-radius: 90px;
    }
  }

  &-left {
    border-top-left-radius: 240px;
    @include v-md {
      border-top-left-radius: 140px;
    }
    @include v-sm {
      border-top-left-radius: 90px;
    }
  }
}

.link {
  color: $goldHovered;
}

.plank {
  height: 240px;
  @include v-md {
    height: 140px;
  }
  @include v-sm {
    height: 90px;
  }
}
.dn {
  display: none;
}

.fixed {
  position: fixed !important;
}

.relative {
  position: relative !important;
}

.flex {
  display: flex !important;
}

.fdr {
  flex-direction: row;
}

.fdc {
  flex-direction: column;
}

.jcc {
  justify-content: center !important;
}

.jcsb {
  justify-content: space-between !important;
}

.aic {
  align-items: center !important;
}

.tc {
  text-align: center !important;
}

.tj {
  text-align: justify !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;

  @include v-md {
    margin-top: 18px !important;
  }
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-md-10 {
  @include v-md {
    margin-right: 10px !important;
  }
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-20 {
  margin-left: 20px !important;

  @media only screen and (max-width: 575px) {
    margin-left: 0 !important;
  }
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-sm-40 {
  @media only screen and (max-width: 767px) {
    margin-bottom: 40px !important;
  }
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-md-9 {
  @include v-md {
    margin-bottom: 9px !important;
  }
}

.mb-md-10 {
  @include v-md {
    margin-bottom: 10px !important;
  }
}

.mb-md-18 {
  @include v-md {
    margin-bottom: 18px !important;
  }
}

.mb-md-23 {
  @include v-md {
    margin-bottom: 23px !important;
  }
}

.mb-md-35 {
  @include v-md {
    margin-bottom: 35px !important;
  }
}

.mb-md-20 {
  @include v-md {
    margin-bottom: 20px !important;
  }
}

.mb-sm-24 {
  @include v-sm {
    margin-bottom: 24px !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;

  @include v-md {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

.gap-8 {
  gap: 8px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-md-10 {
  @include v-md {
    gap: 10px !important;
  }
}

.gap-24 {
  gap: 24px !important;

  @include v-md {
    gap: 18px !important;
  }
}

.gap-40 {
  gap: 40px !important;
}

.gap-md-23 {
  @include v-md {
    gap: 23px !important;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.w-30 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.w-24 {
  width: 24px !important;
}

.w-md-18 {
  @include v-md {
    width: 18px !important;
  }
}

.w {
  &-md-75 {
    @include v-md {
      width: 75% !important;
    }
  }

  &-md-100 {
    @include v-md {
      width: 100% !important;
    }
  }
}

.h-24 {
  height: 24px !important;
}

.h-md-18 {
  @include v-md {
    height: 18px !important;
  }
}

.w-100 {
  width: 100% !important;
}
.tdn {
  text-decoration: none !important;
}

.lsi {
  list-style: inside !important;
}

.white-space-nw {
  white-space: nowrap;
}

#cookie {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 80%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  z-index: 1100;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  pointer-events: auto;

  @include v-md {
    padding: 20px;
  }

}

#cookie::-webkit-scrollbar  {
    width: 0;
    background: transparent;
}

.cookie-modal-open {
  pointer-events: none;
}

#hero {
  background: #152d2c url('../../assets/images/hero.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  text-align: left;
  @include v-sm {
    background-position: 90%;
  }

  li {
    margin-bottom: 1.25rem;
    padding: 1.25rem;

    @media only screen and (max-width: 575px) {
      padding: 0;
    }
  }

  li:hover {
    @media only screen and (min-width: 768px) {
      background-color: rgba(201, 141, 38, 0.25);
    }
  }
}

.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  text-align: left;
  min-height: $hero-min-h;
  @include v-md {
    min-height: $hero-md-min-h;
  }
  @include v-sm {
    min-height: $hero-md-min-h;
  }
  @include v-xs {
    min-height: $hero-xs-min-h;
  }
}

.hero-plan-the-future {

  background: #152d2c url('../../assets/images/bg_black-tesla.webp');
}

.hero-to-success {
  background: #152d2c url('../../assets/images/bg_white-car.webp');

  background: #152d2c url('../../assets/images/bg_black-tesla.webp');
}

.hero-to-success {
  background: #152d2c url('../../assets/images/bg_white-car.webp');

  @include v-lg {
    background-position: 50%;
  }
}

.hero-hay-boss {

  background: #152d2c url('../../assets/images/bg_coins.webp');

  background: #152d2c url('../../assets/images/bg_coins.webp');

  @include v-lg {
    background-position: 0%;
  }
}

.hero-join-our-retailer {

  background: #152d2c url('../../assets/images/bg_finger-up.webp');

  background: #152d2c url('../../assets/images/bg_finger-up.webp');

  @include v-lg {
    background-position: 0%;
  }
}

.ready-to-transform {
  &__item {
    position: relative;
    margin: 32px auto 0;
    padding: 20px 20px 20px 60px;
    border: 1px solid $dark;
    text-align: start;
    border-radius: 20px;
    max-width: 976px;
    @include v-md {
      padding: 15px 15px 15px 40px;
    }
  }

  &__img {
    position: absolute;
    left: 20px;
    top: 20px;
    @include v-md {
      width: 20px;
      left: 15px;
      top: 15px;
    }
  }
}

.get-the-benefits__item {
  padding: 20px;
  border-radius: 20px;
  height: 100%;

  img {
    @include v-md {
      width: 36px;
    }
  }
}

#plan_the_future {
  ul {
    margin-bottom: 3.75rem;
    @media only screen and (max-width: 991px) {
      margin-bottom: 1.25rem;
    }
  }

  li {
    padding: 1.5rem 2.5rem 5.625rem;
    position: relative;
    @media only screen and (max-width: 991px) {
      padding: 0.875rem 1rem 1.85rem;
      margin-bottom: 0.625rem;
    }

    &:nth-child(2) {
      background-color: RGBA(42, 154, 149, 0.12);
    }
  }

  .arrowUp {
    position: absolute;
    bottom: 1rem;
    right: -6.875rem;

    @media only screen and (max-width: 991px) {
      transform: rotate(90deg);
      bottom: -6.375rem;
      left: -5.2rem;
    }
  }

  .arrowDown {
    position: absolute;
    top: 1rem;
    right: -6.25rem;

    @media only screen and (max-width: 991px) {
      transform: rotate(90deg);
      top: 20rem;
      right: -5rem;
    }
  }
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#to_success {
  li {
    border-top: 0.188rem solid $green;
    &:hover,
    &:focus {
      border-top: 0.188rem solid $white;
    }
  }
  li:first-child {
    @media only screen and (max-width: 991px) {
      margin-bottom: 2.5rem;
    }
  }
}

#greatful {

  background: #152d2c url(../../assets/images/grateful_bg.png);
}

#partner {
  background: #152d2c url('../../assets/images/bg_white-car.webp');

  background: #152d2c url(../../assets/images/grateful_bg.png);
}

#partner {
  background: #152d2c url('../../assets/images/bg_white-car.webp');

  padding: 10.375rem 0rem 25rem;
  background-position: center;
  background-size: cover;
  @include v-md {
    padding: 6.25rem 0rem 18.75rem;
  }
  @include v-sm {
    padding: 3.75rem 0rem 18.75rem;
  }
}

#investor {

  background: #152d2c url('../../assets/images/bg_white-car.webp');

  background: #152d2c url('../../assets/images/bg_white-car.webp');

  padding: 10.375rem 0rem 25rem;
  background-position: center;
  background-size: cover;
  @include v-md {
    padding: 6.25rem 0rem 18.75rem;
  }
  @include v-sm {
    padding: 3.75rem 0rem 18.75rem;
  }
}

#webinar {
  padding: 10.375rem 0rem 25rem;

  background: #152d2c url('../../assets/images/bg_black-tesla.webp');

  background: #152d2c url('../../assets/images/bg_black-tesla.webp');

  background-position: center;
  background-size: cover;
  @include v-md {
    padding: 6.25rem 0rem 18.75rem;
  }
  @include v-sm {
    padding: 3.75rem 0rem 18.75rem;
  }
}

#privacy-policy {

  background: #152d2c url('../../assets/images/bg_privacy-policy.webp');

  background: #152d2c url('../../assets/images/bg_privacy-policy.webp');

  background-size: cover;
  background-position: center;
}

#retail {

  background: #152d2c url('../../assets/images/bg_retail.webp');

  background: #152d2c url('../../assets/images/bg_retail.webp');

  background-size: cover;
  background-position: center;
}

.width-max-content {
  width: max-content !important;
}
